/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import urlImag1 from "../../../../assets/images/pricing/01.jpg";
import urlImag2 from "../../../../assets/images/pricing/02.jpg";
import urlImag3 from "../../../../assets/images/pricing/03.jpg";

export default [
  {
    title: "Attendant care",
    description:
      "Professional, compassionate support designed to enhance your daily living and promote independence for a better quality of life",
    image: `${urlImag1}`,
    items: [
      { name: "◉ Changing diapers of patients" },
      { name: "◉ Helping in bathing" },
      { name: "◉ Mild massages" },
      { name: "◉ Assistance to washroom and back" },
      { name: "◉ Assistance in changing clothes" },
      { name: "◉ Giving food and medicine on time" },
      { name: "◉ Clearing urine catheter from time to time" },
      { name: "◉ Keeping patient's room clean" },
      { name: "◉ Taking care of basic hygiene" },
      { name: "◉ Companionship" },
    ],
  },
  {
    title: "Seminurse care",
    description:
      "Professional support encompassing medical procedures, device assistance, and compassionate companionship, ensuring optimal home care experiences",
    image: `${urlImag2}`,
    items: [
      {
        name: "◉ All of the functions of the attendant as mentioned above (except massages)",
      },
      {
        name: "◉ Feeding in ryles tube",
      },
      {
        name: "◉ Checking vitals (BP, sugar)",
      },
      {
        name: "◉ Giving insulin shots",
      },
      {
        name: "◉ Assistance in Nebulizer, Oxygen concentrator, bilap machine",
      },
      {
        name: "◉ Keeping patient's room clean",
      },
      {
        name: "◉ Companionship",
      },
    ],
  },
  {
    title: "Fully trained nurse",
    description:
      "Expert care provider offering comprehensive medical support, adept device management, and empathetic companionship for superior home healthcare services",
    image: `${urlImag3}`,
    items: [
      {
        name: "◉ All the functions of Seminurse",
      },
      {
        name: "◉ IV/IM injection",
      },
      {
        name: "◉ Tracheostomy cases",
      },
      {
        name: "◉ Colostomy bag cases",
      },
      {
        name: "◉ Assistance in taking care of medical devices in home ICU setup",
      },
      {
        name: "◉ Keeping patient's room clean",
      },
      {
        name: "◉ Companionship",
      },
    ],
  },
];
