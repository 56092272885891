/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/pagesData";

function Pages() {
  const renderData = data.map(({ image, name, route, ...rest }) => (
    <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
      <Link to={route}>
        <MKBox
          bgColor="white"
          borderRadius="xl"
          shadow="lg"
          minHeight="9rem"
          padding="2rem"
          sx={{
            overflow: "hidden",
            transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
            transformOrigin: "50% 0",
            backfaceVisibility: "hidden",
            willChange: "transform, box-shadow",
            transition: "transform 200ms ease-out",

            "&:hover": {
              transform: "perspective(999px) rotateX(7deg) translate3d(0px, -4px, 5px)",
            },
          }}
          {...rest}
        >
          <MKBox
            component="img"
            src={image}
            alt={image}
            width="100%"
            my="auto"
            opacity={1}
            height={"18rem"}
          />
          <MKTypography variant="h6" fontWeight="bold">
            {name}
          </MKTypography>
        </MKBox>
      </Link>
    </Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Why Choose Us"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            We Provide Special Service For Patients
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3}>
            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
              <MKTypography variant="h3" fontWeight="bold" mb={1}>
                Gallery
              </MKTypography>
              <MKTypography variant="h6" fontWeight="bold">
                We Provide Special Service For Patients -"A Visual Journey Through Creativity"
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
