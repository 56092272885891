/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import swal from "sweetalert";
import { Input, Select } from "antd";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { db, analytics } from "../../../firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import React, { useState, useEffect } from "react";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/illustrations/illustration-reset.jpg";

function ContactUs() {
  const [data, setData] = useState({
    name: "",
    services: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [error, setError] = useState({
    name: false,
    services: false,
    email: false,
    mobile: false,
    message: false,
  });

  const handleInputData = (e) => {
    setError({ ...error, [e.target.name]: false });
    if (e.target.name === "mobile" && isNaN(e.target.value)) {
      return;
    }
    if (e.target.value.length > limits[e.target.name].max) return;
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    const tempError = {};

    Object.entries(limits).forEach(([key, limit]) => {
      if (limit.min && data[key].trim().length < limit.min) tempError[key] = true;
    });

    if (!data.services) tempError.services = true;

    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!data.email.match(validRegex)) {
      tempError.email = true;
    }

    setError(tempError);

    if (Object.keys(tempError).length > 0) return;

    // fetch(`https://script.google.com/macros/s/AKfycbxJYTHYJ6qoe5uS8ztSY1TKx6YLiQMo_PMZLUlL37s/dev`, {
    // 	method: "POST",
    // 	headers: {
    // 		"content-type": "Application/json",
    // 		'Access-Control-Allow-Origin': 'http://localhost:3000',
    // 		'Access-Control-Allow-Credentials':'true'
    // 	},
    // 	mode: 'no-cors',
    // 	body: JSON.stringify(data),
    // })
    // 	.then((res) => res.json())
    // 	.then((res) => {
    // 		if (res?.success) {
    // 			swal("Success!", res?.message, "success");
    // 		} else {
    // 			swal("Error!", res?.message || "Internal server error", "error");
    // 		}
    // 		setData({
    // 			name: "",
    // 			schoolName: "",
    // 			email: "",
    // 			mobile: "",
    // 			studentCount: null,
    // 		});
    // 		setLoading(false);
    // 	});

    handleSubmit(data);
  };

  const handleSubmit = async (data) => {
    // e.preventDefault()
    try {
      await addDoc(collection(db, "userList"), {
        name: data.name,
        services: data.services,
        email: data.email,
        message: data.message,
        mobile: data.mobile,
        created: Timestamp.now(),
      }).then(() => {
        swal("Success!", "We will get back to you shortly", "success");
        setData({
          name: "",
          services: "",
          email: "",
          mobile: "",
          message: "",
        });
      });
    } catch (err) {
      swal("Error!" || "Internal server error", "error");
      alert(err);
    }
  };
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            label: "Enquiry",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Contact us
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKBox width="100%" component="form" method="post" autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Input
                      value={data.name}
                      name="name"
                      onChange={handleInputData}
                      size="large"
                      placeholder="Name*"
                    />
                    {error.name && (
                      <p
                        style={{
                          fontSize: "12px",
                          marginTop: "6px",
                          marginLeft: "4px",
                          color: "#ff2222",
                        }}
                      >
                        {data.name.trim() === ""
                          ? "Enter valid name"
                          : `Minimum ${limits.name.min} characters required`}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      value={data.email}
                      name="email"
                      onChange={handleInputData}
                      size="large"
                      placeholder="Email*"
                    />
                    {error.email && (
                      <p
                        style={{
                          fontSize: "12px",
                          marginTop: "6px",
                          marginLeft: "4px",
                          color: "#ff2222",
                        }}
                      >
                        {"Enter valid email id"}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      value={data.mobile}
                      name="mobile"
                      onChange={handleInputData}
                      size="large"
                      placeholder="Mobile*"
                    />
                    {error.mobile && (
                      <p
                        style={{
                          fontSize: "12px",
                          marginTop: "6px",
                          marginLeft: "4px",
                          color: "#ff2222",
                        }}
                      >
                        {data.mobile.trim() === ""
                          ? "Enter valid phone"
                          : `Minimum ${limits.mobile.min} characters required`}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      value={data.services}
                      name="services"
                      onChange={handleInputData}
                      size="large"
                      placeholder="Service Name*"
                    />
                    {error.services && (
                      <p
                        style={{
                          fontSize: "12px",
                          marginTop: "6px",
                          marginLeft: "4px",
                          color: "#ff2222",
                        }}
                      >
                        {data.services.trim() === ""
                          ? "Enter valid service name"
                          : `Minimum ${limits.services.min} characters required`}
                      </p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Input
                      value={data.message}
                      name="message"
                      onChange={handleInputData}
                      size="large"
                      placeholder="Message"
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      onSubmit();
                    }}
                  >
                    Send Message
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;

const limits = {
  name: {
    min: 3,
    max: 50,
  },
  message: {
    min: 0,
    max: 1000,
  },
  services: {
    min: 3,
    max: 50,
  },
  email: {
    min: 5,
    max: 255,
  },
  mobile: {
    min: 10,
    max: 10,
  },
};
