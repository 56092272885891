/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    We Provide Special
                    <br />
                    Service For Patients
                  </>
                }
                description="If you have any questions or need help contact with our team, or call +91-8874154462 and  +91-9580325626"
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="Connect with us"
                action={{
                  type: "external",
                  route: "tel:+918874154462",
                  label: "Call US +91-8874154462",
                }}
                action1={{
                  type: "external",
                  route: "tel:+919580325626",
                  label: "Call US +91-9580325626",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="monitor_heart"
                  title="Emergency Care At Home"
                  description="Experience peace of mind with our Emergency Care at Home service. Swift response, expert care, all in the comfort of your own space."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="vaccines"
                  title="On Call Injection"
                  description="At Unique Home Patient Care Pvt. Ltd., we understand that medical treatments often involve injections that require precision and expertise"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="favorite"
                  title="Nursing Care At Home"
                  description="At Unique Home Patient Care Pvt. Ltd., we understand that personalized nursing care is the cornerstone of your well-being."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="directions_car"
                  title="Ambulance service"
                  description="Reliable emergency response, rapid medical assistance, and compassionate care. Trust us to be there when every second counts."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="medication"
                  title="Doctor visit at home"
                  description="At Unique Home Patient Care Pvt. Ltd., we understand that personalized nursing care is the cornerstone of your well-being."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
