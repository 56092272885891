/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import img1 from "../../../../assets/images/services/service-01.jpg";
import img2 from "../../../../assets/images/services/service-02.jpg";
import img3 from "../../../../assets/images/services/service-03.jpg";
import img4 from "../../../../assets/images/services/service-04.jpg";

export default [
  {
    image: `${img1}`,
  },
  {
    image: `${img2}`,
  },
  {
    image: `${img3}`,
  },
  {
    image: `${img4}`,
  },
];
