/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/designBlocksData";
import Divider from "assets/theme/components/divider";
import image1 from "../../../assets/images/pricing/01.jpg";

function DesignBlocks() {
  const renderData = data.map(({ title, description, items, image }, index) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <MKBox
          container
          bgColor="white"
          borderRadius="xl"
          shadow="lg"
          padding={"2rem"}
          sx={{
            overflow: "hidden",
            transform: "perspective(999px) rotateX(0deg) translate3d(0, 0, 0)",
            transformOrigin: "50% 0",
            backfaceVisibility: "hidden",
            willChange: "transform, box-shadow",
            transition: "transform 200ms ease-out",
            "&:hover": {
              transform: "perspective(999px) rotateX(1deg) translate3d(0px, -4px, 5px)",
            },
          }}
        >
          <MKBox
            component="img"
            src={image}
            alt={image}
            width="100%"
            my="auto"
            opacity={1}
            height={"18rem"}
          />
          {items.map(({ image, name, count }) => (
            <MKBox container>
              {/* <Divider /> */}

              <MKTypography variant="h6" fontWeight="bold" padding={"0.5rem"}>
                {name}
              </MKTypography>
            </MKBox>
          ))}
        </MKBox>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Unique Home Care"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Our Work Process
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Every decision and action revolves around delivering the highest quality of care and
            service to our patients.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
