// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";
import Instagram from "@mui/icons-material/Instagram";
import { WhatsApp } from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Unique Home Patient Care Pvt. Ltd.",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/profile.php?id=61561216143770&mibextid=JRoKGi",
    },
    {
      icon: <Instagram />,
      link: "https://www.instagram.com/uniquehomepatientcarepvtltd",
    },
    {
      icon: <WhatsApp />,
      link: "whatsapp://send?phone=+918874154462&text=Hi!/",
    },
  ],
  menus: [
    {
      name: "24/7 Hours Services",

      items: [
        { name: "Elder/Old Age Care at Home" },
        { name: "Cancer Care at Home" },
        { name: "Baby Care at Home" },
        { name: "HouseKeeping Staff at Home" },
        { name: "ICU set-up" },
        { name: "Caretaker at Home" },
      ],
    },
    {
      name: "Get in Touch",
      items: [
        {
          name: "T-2532 G.F, Gali No-21A Baljeet Nagar Near Vishk New Delhi Patel Nagar East Central delhi-110008 Delhi",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Unique Home Patient Care Pvt. Ltd.{" "}
    </MKTypography>
  ),
};
