import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import "./superadmin.module.css";

export default function Superadmin() {
  const [firebaseDAta, setFirebaseData] = useState([]);

  const fetchPost = async () => {
    await getDocs(collection(db, "userList")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setFirebaseData(newData);
      console.log(firebaseDAta, newData);
    });
  };

  useEffect(() => {
    fetchPost();
  }, []);
  return (
    <div
      style={{
        padding: "40px 100px",
        background: "#FAFAFA",
        borderRadius: "24px",
        maxWidth: "calc(100%)",
        marginTop: "2px",
        minHeight: "230px",
        overflowX: "auto",
      }}
    >
      <table>
        <caption>Unique Home Patient Care Pvt. Ltd.</caption>
        <thead>
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">name</th>
            <th scope="col">mobile</th>
            <th scope="col">EMAIL</th>
            <th scope="col">Services</th>
            <th scope="col">Message</th>
          </tr>
        </thead>
        <tbody>
          {firebaseDAta?.map((data, i) => (
            <tr>
              <td data-label="S.No">{i + 1}</td>
              <td data-label="name">{data.name}</td>
              <td data-label="mobile">{data.mobile}</td>
              <td data-label="EMAIL">{data.email}</td>
              <td data-label="CITY">{data.services}</td>
              <td data-label="class">{data.message}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
