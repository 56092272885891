/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import DesignBlocks from "pages/Presentation/sections/DesignBlocks";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";
import Download from "pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.jpg";

function Presentation() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/pages/landing-pages/contact-us",
          label: "Enquiry",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={10} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Unique Home Patient Care Pvt. Ltd.
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              We have the best health care team for you.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <DesignBlocks />
        <Pages />
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Testimonials />
        {/* <Download /> */}
        <Grid container style={{ justifyContent: "center" }}>
          <MKTypography variant="h3" color="black" textAlign="center" px={{ xs: 6, lg: 12 }} mt={1}>
            We have the best health care team for you.
            <br />
            <MKTypography
              variant="h5"
              color="grey"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Second director
            </MKTypography>
          </MKTypography>
          <Grid container style={{ justifyContent: "center" }}>
            <MKTypography
              variant="body1"
              color="black"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Vandana Pandey
              <MKTypography
                variant="body1"
                color="black"
                textAlign="center"
                px={{ xs: 0, lg: 0 }}
                mt={0}
              >
                +91-9580325626
              </MKTypography>
            </MKTypography>
            <MKTypography
              variant="body1"
              color="black"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
              Shivam Shukla
              <MKTypography
                variant="body1"
                color="black"
                textAlign="center"
                px={{ xs: 0, lg: 0 }}
                mt={0}
              >
                +91-8874154462
              </MKTypography>
            </MKTypography>
          </Grid>
        </Grid>
        <MKBox pt={18} pb={2}>
          <Container>
            <Grid container style={{ justifyContent: "center" }}>
              <Grid
                item
                alignContent={"center"}
                alignSelf={"center"}
                alignItems="center"
                sx={{ textAlign: { xs: "center", lg: "center" } }}
              >
                <MKSocialButton
                  component="a"
                  href="https://www.facebook.com/profile.php?id=61561216143770&mibextid=JRoKGi"
                  target="_blank"
                  color="facebook"
                  sx={{ mr: 1 }}
                >
                  <i className="fab fa-facebook" />
                  &nbsp;Share
                </MKSocialButton>
                <MKSocialButton
                  component="a"
                  href="whatsapp://send?phone=+918874154462&text=Hi!/"
                  target="_blank"
                  color="vimeo"
                >
                  <i className="fab fa-whatsapp" />
                  &nbsp;Whatsapp
                </MKSocialButton>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.2704447988335!2d77.15346671640876!3d28.651619897230876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02e530d65c5d%3A0xfa4bbc0a27aad3be!2sShadipur!5e0!3m2!1sen!2sin!4v1719071279802!5m2!1sen!2sin"
        width="100%"
        height="450"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      />

      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
